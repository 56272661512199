


import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import Axios from "axios";
import {
  borrowerData,
  coBorrowerDetail
} from "../../../models/borrowerData.model";

import {
  PRODUCT_TYPE,
  PROPERTY_TYPE,
  OCCUPANCY,
  LOAN_TERM,
  AMORTIZATION_TYPE,
  LOAN_PURPOSE,
  ALLOWED_FILE_EXTENSIONS
} from "@/constants";
import { BASE_API_URL } from "@/config";
import BootstrapVue from "bootstrap-vue";
import { VueEditor } from "vue2-editor";
import AddressComponent from "@/views/Address.vue";
import { Multiselect } from "vue-multiselect";
import stores from "@/store/store";
const store: any = stores;
import saveAs from "save-as";

@Component({ components: { VueEditor, AddressComponent, Multiselect } })
export default class ScenarioDeskComponent extends Vue {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  public borrowerData: any = new borrowerData();
  public message = null;
  public isEmailHasBody = true;
  public email = null;
  public lenders = [];
  public loanTerm = LOAN_TERM;
  public selectedLender = [];
  public selectedLendersEmailAndId = [];
  public isLendersNotSelected = false;
  public productType = PRODUCT_TYPE;
  public propertyType = PROPERTY_TYPE;
  public occupancy = OCCUPANCY;
  public amortizationType = AMORTIZATION_TYPE;
  public loanPurpose = LOAN_PURPOSE;
  public subject = null;
  public inProcess = false;
  // public sendLoanDetail = true;
  public file: any = [];
  public fileType = null;
  public dotLoader: any = null;
  public fileToDisplay = null;
  public currentFileIndex = 0;
  public showNextButton = false;
  public showPreviousButton = false;
  public loanStateList = [];
  public countiesApi = [];
  public customToolBar = [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }]
  ];
  public scenarioDeskData: any = {
    address: {
      zip: null,
      state: {
        name: null,
        code: null
      },
      county: null
    }
  };
  public pvError = null;
  public laError = null;
  public isReset = false;
  public zipLoader = false;
  public countyError = false;
  public filters = null;
  public get stateList() {
    return store.state.sessionObject.states;
  }
  //to get the name of all lenders from lender-relation
  public async getAllLendersforScenarioDesk(searchByName: string = null) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/getAllLendersforScenarioDesk",
        {
          userType: this.$store.state.sessionObject.type,
          searchByName: searchByName,
          userId: this.$userId,
          brokerId: this.$brokerId
        });
      this.lenders = [
        {
          select: "Select All",
          libs: response.data
        }
      ];
    } catch (error) {
      console.log(error);
    }
  }

  public async selectAll() {
    await this.getAllLendersforScenarioDesk("select all");
  }

  async setLtvDownPaymentOrLoanAmount(type, calledFrom = "other") {
    try {
      let pv: any = parseFloat(this.scenarioDeskData.propertyValue);
      let la: any = parseFloat(this.scenarioDeskData.loanAmount);
      this.pvError = null;
      this.laError = null;
      if (la > pv) {
        if (calledFrom == "propertyValue") {
          this.pvError = "Property value cannot be less than loan amount.";
        } else {
          this.laError = "Loan amount cannot be more than property value.";
        }
        return;
      }

      if (pv != 0) {
        let ltv = (la / pv) * 100;
        ltv = isNaN(ltv) || !isFinite(ltv) ? 0 : Math.round(ltv * 100) / 100;
        this.scenarioDeskData.LTV = JSON.parse(JSON.stringify(ltv));
        this.scenarioDeskData.CLTV = JSON.parse(JSON.stringify(ltv));
        this.isReset = true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async saveScenarioDesk() {
    let formValidate = await this.$validator.validateAll("scenerio");
    if (!formValidate || this.selectedLender.length == 0) {
      if (this.selectedLender.length == 0) {
        this.isLendersNotSelected = true;
      }
      if (!this.scenarioDeskData.county) {
        this.countyError = true;
      }
      this.inProcess = false;
      this.$store.state.wemloLoader = false;
      return;
    }
    try {
      this.inProcess = true;
      this.$store.state.wemloLoader = true;
      this.scenarioDeskData.firstName =
        this.scenarioDeskData.firstName.charAt(0).toUpperCase() +
        this.scenarioDeskData.firstName.substring(1).toLowerCase();
      this.scenarioDeskData.lastName =
        this.scenarioDeskData.lastName.charAt(0).toUpperCase() +
        this.scenarioDeskData.lastName.substring(1).toLowerCase();

      let formData = new FormData();
      this.file.forEach((element: any) => {
        formData.append("file", element);
      });

      let dataOfLenders = {
        scenarioDeskData: this.scenarioDeskData,
        selectedLenders: this.selectedLender,
        message: this.message,
        // sendLoanDetail: this.sendLoanDetail,
        subject: this.subject,
        loanTxnId: this.$random(8),
        senderName:
          this.$store.state.sessionObject.userInfo.firstName +
          " " +
          this.$store.state.sessionObject.userInfo.lastName,

        brokerId: this.$brokerId,
        userId: this.$userId
      };
      formData.append("data", JSON.stringify(dataOfLenders));
      await Axios.post(
        BASE_API_URL + "broker/SendMailToSelectedLenders",
        formData);
      this.$snotify.success("Email Sent Sucessfully");
      this.clearAllFields();
      this.$modal.show("scnerioSubmitted");

      this.inProcess = false;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.inProcess = false;
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  public clearAllFields() {
    this.countyError = false;
    this.countiesApi = [];
    this.loanStateList = [];
    this.selectedLender = [];
    this.selectedLendersEmailAndId = [];
    this.message = "";
    this.subject = null;
    this.file = [];
    this.scenarioDeskData = {
      address: {
        zip: null,
        state: {
          name: null,
          code: null
        },
        county: null
      }
    };
  }
  public goToDashboard() {
    this.$router.push({ path: "dashboard" });
  }

  public async toSeeAttachment(action = null) {
    if (action == "previous") {
      this.currentFileIndex--;
    }
    if (action == "next") {
      this.currentFileIndex++;
    }

    this.file.forEach((element, i) => {
      if (this.currentFileIndex == i) {
        this.fileToDisplay = URL.createObjectURL(element);
        let file = element.name.split(".");
        this.fileType = file[file.length - 1];
      }
      if (this.file.length == 1) {
        this.showPreviousButton = false;
        this.showNextButton = false;
      } else if (this.currentFileIndex == 0) {
        this.showPreviousButton = false;
        this.showNextButton = true;
      } else if (this.currentFileIndex == this.file.length - 1) {
        this.showPreviousButton = true;
        this.showNextButton = false;
      } else {
        this.showPreviousButton = true;
        this.showNextButton = true;
      }
    });

    if (!action) this.$modal.show("toSeeAttachment");
  }
  public async downloadFile(file) {
    file = this.file[this.currentFileIndex];
    saveAs(file, file.name);
  }
  public storedFile(event) {
    if (event.target.files.length == 0) {
      return;
    }
    this.file = [];
    Object.values(event.target.files).forEach(element => {
      this.file.push(element);
    });
  }
  public clearAttachment(fileToDisplay) {
    if (this.file.length == 1) {
      let mailAttachment: any = this.$refs.mailAttachment;
      mailAttachment.value = null;
    }
    this.file.splice(this.currentFileIndex, 1);
    this.currentFileIndex = 0;
    if (this.file.length == 1) {
      this.showNextButton = false;
    }
    this.toSeeAttachment();

    if (this.file.length == 0) {
      this.currentFileIndex = 0;
      this.$modal.hide("toSeeAttachment");
    }
  }
  public async getCityAndState() {
    let findState: any = null;
    let findCounty: any = null;
    this.loanStateList = [];
    this.countiesApi = [];
    this.zipLoader = true;
    let zip = this.scenarioDeskData.address.zip;
    let response = await Axios.get(
      "https://maps.googleapis.com/maps/api/geocode/json?sensor=true&key=AIzaSyC4z_vM_4vNFs5_KH5wUdTjJrP8yLS9lr8&components=postal_code:" +
        zip,
        { headers: { public: true}}
    );
    this.zipLoader = false;
    if (response.status == 200 && response.data.status == "OK") {
      response.data.results[0]["address_components"].forEach(element => {
        if (element.types.includes("administrative_area_level_2")) {
          findCounty = element.long_name;
        }
        if (element.types.includes("administrative_area_level_1")) {
          findState = element;
        }
      });
      if (!findState) {
        this.stateList.forEach(data => {
          this.loanStateList.push(data);
        });
      } else {
        this.loanStateList.push({
          state: findState.long_name,
          code: findState.short_name
        });
        this.scenarioDeskData.address.state = {
          name: findState.long_name,
          code: findState.short_name
        };
      }
      if (!findCounty) {
        this.scenarioDeskData.address.county = null;
        let state = this.scenarioDeskData.address.state;
        this.stateList.find(data => {
          if (state && state.code == data.code) {
            this.countiesApi = data.counties;
          }
        });
      } else {
        this.countiesApi.push(findCounty);
        this.scenarioDeskData.address.county = findCounty;
      }
    }
    if (response.data.status == "ZERO_RESULTS") {
      this.stateList.forEach(data => {
        this.loanStateList.push(data);
      });
      if (
        this.scenarioDeskData.address.state &&
        this.scenarioDeskData.address.state.code
      ) {
        this.getCounties(this.scenarioDeskData.address.state);
      } else {
        this.scenarioDeskData.address.state = null;
      }
    }
  }
  public getCounties(state) {
    this.countiesApi = [];
    let seletedStateData = this.stateList.filter(a => a.state == state.name);
    this.countiesApi = seletedStateData[0].counties;
  }
  public onStateChange() {
    this.scenarioDeskData.address.county = null;
    let state = this.scenarioDeskData.address.state;
    this.stateList.find(data => {
      if (state && data.code == state.code) {
        this.countiesApi = data.counties;
      }
    });
  }

public setDefaultValues() {
    // if (!this.scenarioDeskData.loanInformation.productType) {
    //   this.borrowerData.loanInformation.productType = this.productType[0];
    // }
    if (!this.scenarioDeskData.amortizationType) {
      this.scenarioDeskData.amortizationType = this.amortizationType[0];
    }
    if (!this.scenarioDeskData.term) {
      this.scenarioDeskData.term = this.loanTerm[4];
    }
    if (!this.scenarioDeskData.occupancy) {
      this.scenarioDeskData.occupancy = this.occupancy[2];
    }
    if (!this.scenarioDeskData.propertyType) {
      this.scenarioDeskData.propertyType = this.propertyType[0];
    }  
    if (!this.scenarioDeskData.purpose) {
      this.scenarioDeskData.purpose = this.loanPurpose[0];
    }
  }






  @Watch("scenarioDeskData.propertyValue")
  setPropertyValueValidation() {
    this.setLtvDownPaymentOrLoanAmount("setDownPayment", "propertyValue");
  }
  @Watch("scenarioDeskData.loanAmount")
  setLoanAmountValidation() {
    this.setLtvDownPaymentOrLoanAmount("setDownPayment", "loanAmount");
  }
  mounted() {
    this.getAllLendersforScenarioDesk();
    this.setDefaultValues();
  }
}
